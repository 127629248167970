.landing-page {
    background: url("../../assets/bg/sm-full.jpg"), url("../../assets/bg/bg-fallback.jpg");
    background-size: 100% 100%;
    background-position: center;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;

    .wrapper {
        flex: 1;
        position: relative;

        .ft-box {
            height: 100vh;

            .music {
                width: calc(100vw / 9);
                height: calc(100vw / 9);
                position: absolute;
                transition: all ease-in-out 1s;
                cursor: pointer;
                z-index: 999;

                padding: 23px;

                img {
                    width: 100%;
                    transition: all ease-in-out .3s;
                    opacity: 0;
                }

                &:hover {

                    img {
                        width: 110%;
                        opacity: 1;
                    }
                }
            }

            .pasar-nft {
                width: calc(100vw / 9);
                height: calc(100vw / 9);
                position: absolute;
                top: 50vh;
                right: 0;
                transition: all ease-in-out .8s;
                cursor: pointer;

                padding: 23px;

                img {
                    width: 100%;
                    transition: all ease-in-out .3s;
                    opacity: 0;
                }

                &:hover {

                    img {
                        width: 110%;

                    }
                }


            }

            .asix-plus {
                width: calc(100vw / 9);
                height: calc(100vw / 9);
                position: absolute;
                top: 50vh;
                right: 0;
                transition: all ease-in-out .5s;
                cursor: pointer;

                padding: 23px;

                img {
                    width: 100%;
                    transition: all ease-in-out .3s;
                    opacity: 0;
                }

                &:hover {

                    img {
                        width: 110%;

                    }
                }
            }

            .earn {
                width: calc(100vw / 9);
                height: calc(100vw / 9);
                position: absolute;
                top: 50vh;
                right: 0;
                transition: all ease-in-out .8s;
                cursor: pointer;
                z-index: 999;

                padding: 23px;

                img {
                    width: 100%;
                    transition: all ease-in-out .3s;
                    opacity: 0;
                }

                &:hover {

                    img {
                        width: 110%;

                    }
                }
            }

            .nusantara {
                width: calc(100vw / 9);
                height: calc(100vw / 9);
                position: absolute;
                top: 50vh;
                right: 0;
                transition: all ease-in-out 1s;
                cursor: pointer;

                padding: 23px;

                img {
                    width: 100%;
                    transition: all ease-in-out .3s;
                    opacity: 0;
                }

                &:hover {

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.landing-page {
    background-size: cover;
    background-position: center;

    .wrapper {
        flex: 1;
        position: relative;

        .ft-box {
            height: 100vh;

            .music {
                width: calc(100vw / 9);
                height: calc(100vw / 9);
                position: absolute;


                img {
                    width: 100%;

                }

            }

            .pasar-nft {
                width: calc(100vw / 9);
                height: calc(100vw / 9);
                position: absolute;
                top: 50vh;
                right: 0;

                img {
                    width: 100%;
                }
            }

            .asix-plus {
                width: calc(100vw / 9);
                height: calc(100vw / 9);
                position: absolute;
                top: 50vh;
                right: 0;

                img {
                    width: 100%;


                }
            }

            .earn {
                width: calc(100vw / 9);
                height: calc(100vw / 9);
                position: absolute;
                top: 50vh;
                right: 0;

                img {
                    width: 100%;
                }
            }

            .nusantara {
                width: calc(100vw / 9);
                height: calc(100vw / 9);
                position: absolute;
                top: 50vh;
                right: 0;

                img {
                    width: 100%;
                }
            }
        }
    }
}

@media (min-width:576px) {
    .landing-page {
        background-size: cover;
        background-position: center;

        .wrapper {
            flex: 1;
            position: relative;

            .ft-box {
                height: 100vh;

                .music {
                    width: calc(100vw / 9);
                    height: calc(100vw / 9);
                    position: absolute;

                    img {
                        width: 100%;
                    }
                }

                .pasar-nft {
                    width: calc(100vw / 9);
                    height: calc(100vw / 9);
                    position: absolute;
                    top: 50vh;
                    right: 0;

                    img {
                        width: 100%;
                    }
                }

                .asix-plus {
                    width: calc(100vw / 9);
                    height: calc(100vw / 9);
                    position: absolute;
                    top: 50vh;
                    right: 0;

                    img {
                        width: 100%;
                    }
                }

                .earn {
                    width: calc(100vw / 9);
                    height: calc(100vw / 9);
                    position: absolute;
                    top: 50vh;
                    right: 0;

                    img {
                        width: 100%;
                    }
                }

                .nusantara {
                    width: calc(100vw / 9);
                    height: calc(100vw / 9);
                    position: absolute;
                    top: 50vh;
                    right: 0;

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media (min-width:768px) {

    .landing-page {
        background-size: cover;
        background-position: center;

        .wrapper {
            flex: 1;
            position: relative;

            .ft-box {
                height: 100vh;

                .music {
                    width: calc(100vw / 9);
                    height: calc(100vw / 9);
                    position: absolute;
                    background-color: transparent;
                    padding: 0;

                    img {
                        width: 100%;

                    }

                }

                .pasar-nft {
                    width: calc(100vw / 9);
                    height: calc(100vw / 9);
                    position: absolute;
                    top: 50vh;
                    right: 0;
                    padding: 0;
                    background-color: transparent;

                    img {
                        width: 100%;
                    }
                }

                .asix-plus {
                    width: calc(100vw / 9);
                    height: calc(100vw / 9);
                    position: absolute;
                    top: 50vh;
                    right: 0;
                    padding: 0;
                    background-color: transparent;

                    img {
                        width: 100%;

                    }
                }

                .earn {
                    width: calc(100vw / 9);
                    height: calc(100vw / 9);
                    position: absolute;
                    top: 50vh;
                    right: 0;
                    padding: 0;
                    background-color: transparent;

                    img {
                        width: 100%;
                    }
                }

                .nusantara {
                    width: calc(100vw / 9);
                    height: calc(100vw / 9);
                    position: absolute;
                    top: 50vh;
                    right: 0;
                    padding: 0;
                    background-color: transparent;

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }


    .music-animate {
        -webkit-animation: music-animate 3s cubic-bezier(0.390, 0.575, 0.565, 1.000) infinite alternate both;
        animation: music-animate 3s cubic-bezier(0.390, 0.575, 0.565, 1.000) infinite alternate both;
    }

    @-webkit-keyframes music-animate {
        0% {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }

        100% {
            -webkit-transform: scale(1.2);
            transform: scale(1.2);
        }
    }

    @keyframes music-animate {
        0% {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }

        100% {
            -webkit-transform: scale(1.2);
            transform: scale(1.2);
        }
    }
}

@media (min-width:992px) {
    .landing-page {
        background: url("../../assets/bg/xl.jpg"), url("../../assets/bg/lg-fallback.jpg");
        background-size: cover;
        background-position: center;

        .wrapper {
            flex: 1;
            position: relative;

            .ft-box {
                height: 100vh;

                .music {
                    width: calc(100vw / 11);
                    height: calc(100vw / 11);
                    position: absolute;
                    padding: 0;
                    background-color: transparent;

                    img {
                        width: 100%;
                        opacity: 1;

                    }
                }

                .pasar-nft {
                    width: calc(100vw / 10);
                    height: calc(100vw / 10);
                    position: absolute;
                    top: 50vh;
                    right: 0;
                    padding: 0;
                    background-color: transparent;

                    img {
                        width: 100%;
                        opacity: 1;
                    }
                }

                .asix-plus {
                    width: calc(100vw / 9);
                    height: calc(100vw / 9);
                    position: absolute;
                    top: 50vh;
                    right: 0;
                    background-color: transparent;
                    padding: 0;

                    img {
                        width: 100%;
                        opacity: 1;
                    }
                }

                .earn {
                    width: calc(100vw / 9);
                    height: calc(100vw / 9);
                    position: absolute;
                    top: 50vh;
                    right: 0;
                    background-color: transparent;
                    padding: 0;

                    img {
                        width: 100%;
                        opacity: 1;
                    }
                }

                .nusantara {
                    width: calc(100vw / 8);
                    height: calc(100vw / 8);
                    position: absolute;
                    top: 50vh;
                    right: 0;
                    background-color: transparent;
                    padding: 0;

                    img {
                        width: 85%;
                        opacity: 1;
                    }
                }
            }
        }
    }
}


.music-animate {
    -webkit-animation: music-animate 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) infinite alternate both;
    animation: music-animate 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) infinite alternate both;
}

@-webkit-keyframes music-animate {
    0% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    100% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
}

@keyframes music-animate {
    0% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    // 50% {
    //     -webkit-transform: scale(1);
    //     transform: scale(1);
    // }

    100% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
}