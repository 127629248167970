.app {
  // background-image: url("./assets/bg/sm-full.png");

  .img-bg {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }

  height: 100vh;
  // background-size: 100vw 100vh;
  // background-position: center center;
  // position: relative;
  // width: 100vw;

  .pasar-nft {
    position: absolute;
    width: 40px;
    // top: 47.3%;
    // left: 29%;
    top: 49vh;
    left: 26vw;

    img {
      width: 100%;
      transition: all ease 0.3s;

      &:hover {
        transform: scale(1.2);
      }
    }
  }

  .a-music {
    position: absolute;
    width: 30px;
    // top: calc(100vh / 2 - 8.2%);
    // left: calc(100vw / 6 - 1%);
    top: 41vh;
    left: 16vw;

    img {
      width: 100%;
      transition: all ease 0.3s;

      &:hover {
        transform: scale(1.2) !important;
      }
    }
  }

  .main-asix {
    position: absolute;
    width: 40px;
    // top: calc(100vh / 2 - 7%);
    // left: calc(100vw / 2 - 5.7%);
    top: 44vh;
    left: 44vw;

    img {
      width: 100%;
      transition: all ease 0.3s;

      &:hover {
        transform: scale(1.2);
      }
    }
  }

  .nusantara-verse {
    position: absolute;
    width: 40px;
    // top: calc(100vh / 2);
    // right: calc(100vw / 7);
    top: 50vh;
    right: 10vw;

    img {
      width: 100%;
      transition: all ease 0.3s;

      &:hover {
        transform: scale(1.2) !important;
      }
    }
  }

  .earn {
    position: absolute;
    width: 40px;
    // top: calc(100vh / 2 - 6%);
    // right: calc(100vw / 9);
    top: 44.3vh;
    right: 5vw;

    img {
      width: 100%;
      transition: all ease 0.3s;

      &:hover {
        transform: scale(1.2) !important;
      }
    }
  }
}

@media screen and (width: 420px) and (max-width : 576px) {
  .app {
    background-size: cover;

    .pasar-nft {
      position: absolute;
      width: 60px;
      top: 47%;
      left: 28.2%;

      img {
        width: 100%;
      }
    }

    .a-music {
      position: absolute;
      width: 60px;
      top: calc(100vh / 2 - 11.6%);
      left: calc(100vw / 8 + 2%);

      img {
        width: 100%;
      }
    }

    .main-asix {
      position: absolute;
      width: 60px;
      top: calc(100vh / 2 - 8%);
      left: calc(100vw / 2 - 7%);

      img {
        width: 100%;
      }
    }

    .nusantara-verse {
      position: absolute;
      width: 70px;
      top: calc(100vh / 2 + 1%);
      right: calc(100vw / 7 - 1px);

      img {
        width: 100%;
      }
    }

    .earn {
      position: absolute;
      width: 80px;
      top: calc(100vh / 2 - 10%);
      right: calc(100vw / 9);

      img {
        transform: rotateX(4deg);
        width: 100%;
      }
    }
  }
}

@media (min-width: 576px) {
  .app {
    background-size: cover;

    .pasar-nft {
      position: absolute;
      width: 70px;
      top: 46%;
      left: 28.2%;

      img {
        width: 100%;
      }
    }

    .a-music {
      position: absolute;
      width: 80px;
      top: calc(100vh / 2 - 14%);
      left: calc(100vw / 8 + 1%);

      img {
        width: 100%;
      }
    }

    .main-asix {
      position: absolute;
      width: 80px;
      top: calc(100vh / 2 - 10%);
      left: calc(100vw / 2 - 7%);

      img {
        width: 100%;
      }
    }

    .nusantara-verse {
      position: absolute;
      width: 70px;
      top: calc(100vh / 2 + 1%);
      right: calc(100vw / 7 - 1px);

      img {
        width: 100%;
      }
    }

    .earn {
      position: absolute;
      width: 80px;
      top: calc(100vh / 2 - 10%);
      right: calc(100vw / 9);

      img {
        transform: rotateX(4deg);
        width: 100%;
      }
    }
  }
}

@media (min-width: 768px) {
  .app {
    // background-image: url("./assets/main-bg.png");
    background-size: cover;

    .a-music {
      position: absolute;
      width: 120px;
      top: calc(100vh / 3 - 6.6%);
      left: calc(100vw / 6 - 11%);

      img {
        width: 100%;
      }
    }

    .pasar-nft {
      position: absolute;
      width: 100px;
      top: 46%;
      left: 23vw;

      img {
        width: 100%;
      }
    }

    .main-asix {
      position: absolute;
      width: 120px;
      top: calc(100vh / 2 - 17%);
      left: calc(100vw / 2 - 8.7%);

      img {
        width: 100%;
      }
    }

    .nusantara-verse {
      position: absolute;
      width: 120px;
      top: calc(100vh / 2 + 3%);
      right: calc(100vw / 10 - 10%);

      img {
        width: 100%;
      }
    }

    .earn {
      position: absolute;
      width: 140px;
      top: calc(100vh / 3 - 20px);
      right: calc(100vw / 9 - 10%);

      img {
        transform: rotateX(4deg);
        width: 100%;
      }
    }
  }
}

@media (min-width: 992px) {
  .app {
    .pasar-nft {
      position: absolute;
      width: 110px;
      top: 46%;
      left: 29.2%;

      img {
        width: 100%;
      }
    }

    .a-music {
      position: absolute;
      width: 110px;
      top: calc(100vh / 3 - 6%);
      left: calc(100vw / 7 + 1%);
      // top: 44vh;
      // left: 44vw;

      img {
        width: 100%;
      }
    }

    .main-asix {
      position: absolute;
      width: 110px;
      top: 35vh;
      left: calc(100vw / 2 - 5.7%);
      // top: calc(100vh / 2 - 18%);
      // left: calc(100vw / 2 - 5.7%);

      img {
        width: 100%;
      }
    }

    .nusantara-verse {
      position: absolute;
      width: 120px;
      top: calc(100vh / 2 + 3%);
      right: calc(100vw / 7 + 1%);

      img {
        width: 100%;
      }
    }

    .earn {
      position: absolute;
      width: 140px;
      top: calc(100vh / 3 - 20px);
      right: calc(100vw / 9);

      img {
        transform: rotateX(4deg);
        width: 100%;
      }
    }
  }
}

@media (min-width: 1024) {
  .app {
    background-image: url("./assets/main-bg.png");
    background-size: cover;

    .a-music {
      position: absolute;
      width: 110px;
      top: calc(100vh / 3 - 8.6%);
      left: calc(100vw / 2 + 120%);

      img {
        width: 100%;
      }
    }

    .pasar-nft {
      position: absolute;
      width: 100px;
      top: 46%;
      left: 20.5%;

      img {
        width: 100%;
      }
    }

    .main-asix {
      position: absolute;
      width: 120px;
      top: calc(100vh / 2 - 17%);
      left: calc(100vw / 2 - 8.7%);

      img {
        width: 100%;
      }
    }

    .nusantara-verse {
      position: absolute;
      width: 120px;
      top: calc(100vh / 2 + 3%);
      right: calc(100vw / 10 - 10%);

      img {
        width: 100%;
      }
    }

    .earn {
      position: absolute;
      width: 140px;
      top: calc(100vh / 3 - 20px);
      right: calc(100vw / 9 - 10%);

      img {
        transform: rotateX(4deg);
        width: 100%;
      }
    }
  }
}

@media (min-width: 1200px) {
  .app {
    background-size: 100%;

    .pasar-nft {
      position: absolute;
      width: 100px;
      top: 40%;
      left: 30.2%;

      img {
        width: 100%;
      }
    }

    .a-music {
      position: absolute;
      width: 120px;
      top: calc(100vh / 3 - 14.2%);
      left: calc(100vw / 7 + 1%);

      img {
        width: 100%;
      }
    }

    .main-asix {
      position: absolute;
      width: 110px;
      top: calc(100vh / 2 - 18%);
      left: calc(100vw / 2 - 4.7%);

      img {
        width: 100%;
      }
    }

    .nusantara-verse {
      position: absolute;
      width: 150px;
      top: calc(100vh / 2 + 3%);
      right: calc(100vw / 7 - 1%);

      img {
        width: 100%;
      }
    }

    .earn {
      position: absolute;
      width: 140px;
      top: calc(100vh / 3 - 20px);
      right: calc(100vw / 9);

      img {
        transform: rotate(-3deg);
        width: 100%;
      }
    }
  }
}

@media (min-width: 1400px) {
  .app {
    background-size: 100%;

    .pasar-nft {
      position: absolute;
      width: 120px;
      // top: 49%;
      left: 30.2%;
      top: 47vh;

      img {
        width: 100%;
      }
    }

    .a-music {
      position: absolute;
      width: 130px;
      // top: calc(100vh / 3 - 13.2%);
      // left: calc(100vw / 7 + 20px);
      top: 23.5vh;

      img {
        width: 100%;
      }
    }

    .main-asix {
      position: absolute;
      width: 130px;
      top: calc(100vh / 2 - 19%);
      left: calc(100vw / 2 - 4.7%);

      img {
        width: 100%;
      }
    }

    .nusantara-verse {
      position: absolute;
      width: 150px;
      top: calc(100vh / 2 + 3%);
      right: calc(100vw / 7 + 1%);

      img {
        width: 100%;
      }
    }

    .earn {
      position: absolute;
      width: 140px;
      top: calc(100vh / 3 - 30px);
      right: calc(100vw / 9);

      img {
        transform: rotate(-3deg);
        width: 100%;
      }
    }
  }
}

// MOBLIE iphone se 
@media screen and (width: 375px),
screen and (height: 667px) {
  .app {
    .img-bg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    height: 100vh;
    background-size: 100vw 100vh;
    background-position: center center;
    position: relative;
    width: 100vw;

    .pasar-nft {
      position: absolute;
      width: 40px;
      // top: 47.3%;
      // left: 29%;
      top: 47.5vh;
      left: 28.6vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2);
        }
      }
    }

    .a-music {
      position: absolute;
      width: 30px;
      // top: calc(100vh / 2 - 8.2%);
      // left: calc(100vw / 6 - 1%);
      top: 41.6vh;
      left: 16vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2) !important;
        }
      }
    }

    .main-asix {
      position: absolute;
      width: 40px;
      // top: calc(100vh / 2 - 7%);
      // left: calc(100vw / 2 - 5.7%);
      top: 43vh;
      left: 44vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2);
        }
      }
    }

    .nusantara-verse {
      position: absolute;
      width: 45px;
      // top: calc(100vh / 2);
      // right: calc(100vw / 7);
      top: 49vh;
      right: 13vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2) !important;
        }
      }
    }

    .earn {
      position: absolute;
      width: 40px;
      // top: calc(100vh / 2 - 6%);
      // right: calc(100vw / 9);
      top: 43vh;
      right: 12vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2) !important;
        }
      }
    }
  }
}

// MOBLIE iphone xr
@media screen and (width: 414px),
screen and (height: 896px) {
  .app {
    .img-bg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    height: 100vh;
    background-size: 100vw 100vh;
    background-position: center center;
    position: relative;
    width: 100vw;

    .pasar-nft {
      position: absolute;
      width: 40px;
      // top: 47.3%;
      // left: 29%;
      top: 48.5vh;
      left: 26.6vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2);
        }
      }
    }

    .a-music {
      position: absolute;
      width: 40px;
      // top: calc(100vh / 2 - 8.2%);
      // left: calc(100vw / 6 - 1%);
      top: 42vh;
      left: 10vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2) !important;
        }
      }
    }

    .main-asix {
      position: absolute;
      width: 45px;
      // top: calc(100vh / 2 - 7%);
      // left: calc(100vw / 2 - 5.7%);
      top: 44vh;
      left: 44vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2);
        }
      }
    }

    .nusantara-verse {
      position: absolute;
      width: 55px;
      // top: calc(100vh / 2);
      // right: calc(100vw / 7);
      top: 50vh;
      right: 8vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2) !important;
        }
      }
    }

    .earn {
      position: absolute;
      width: 40px;
      // top: calc(100vh / 2 - 6%);
      // right: calc(100vw / 9);
      top: 44vh;
      right: 6vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2) !important;
        }
      }
    }
  }
}

// MOBLIE iphone 12 pro
@media screen and (width: 390px),
screen and (height: 844px) {
  .app {
    .img-bg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    height: 100vh;
    background-size: 100vw 100vh;
    background-position: center center;
    position: relative;
    width: 100vw;

    .pasar-nft {
      position: absolute;
      width: 40px;
      // top: 47.3%;
      // left: 29%;
      top: 48.4vh;
      left: 26.6vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2);
        }
      }
    }

    .a-music {
      position: absolute;
      width: 40px;
      // top: calc(100vh / 2 - 8.2%);
      // left: calc(100vw / 6 - 1%);
      top: 41.5vh;
      left: 10vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2) !important;
        }
      }
    }

    .main-asix {
      position: absolute;
      width: 45px;
      // top: calc(100vh / 2 - 7%);
      // left: calc(100vw / 2 - 5.7%);
      top: 44vh;
      left: 44vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2);
        }
      }
    }

    .nusantara-verse {
      position: absolute;
      width: 55px;
      // top: calc(100vh / 2);
      // right: calc(100vw / 7);
      top: 50vh;
      right: 8vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2) !important;
        }
      }
    }

    .earn {
      position: absolute;
      width: 40px;
      // top: calc(100vh / 2 - 6%);
      // right: calc(100vw / 9);
      top: 44vh;
      right: 6vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2) !important;
        }
      }
    }
  }
}

// MOBLIE pxel 5
@media screen and (width: 393px),
screen and (height: 851px) {
  .app {
    .img-bg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    height: 100vh;
    background-size: 100vw 100vh;
    background-position: center center;
    position: relative;
    width: 100vw;

    .pasar-nft {
      position: absolute;
      width: 40px;
      // top: 47.3%;
      // left: 29%;
      top: 48.3vh;
      left: 26.7vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2);
        }
      }
    }

    .a-music {
      position: absolute;
      width: 40px;
      // top: calc(100vh / 2 - 8.2%);
      // left: calc(100vw / 6 - 1%);
      top: 41.5vh;
      left: 10vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2) !important;
        }
      }
    }

    .main-asix {
      position: absolute;
      width: 45px;
      // top: calc(100vh / 2 - 7%);
      // left: calc(100vw / 2 - 5.7%);
      top: 44vh;
      left: 44vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2);
        }
      }
    }

    .nusantara-verse {
      position: absolute;
      width: 55px;
      // top: calc(100vh / 2);
      // right: calc(100vw / 7);
      top: 50vh;
      right: 8vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2) !important;
        }
      }
    }

    .earn {
      position: absolute;
      width: 40px;
      // top: calc(100vh / 2 - 6%);
      // right: calc(100vw / 9);
      top: 44vh;
      right: 6vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2) !important;
        }
      }
    }
  }
}

// MOBLIE Galaxi s 8+
@media screen and (width: 360px),
screen and (max-height: 740px) {
  .app {
    .img-bg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    height: 100vh;
    background-size: 100vw 100vh;
    background-position: center center;
    position: relative;
    width: 100vw;

    .pasar-nft {
      position: absolute;
      width: 40px;
      // top: 47.3%;
      // left: 29%;
      top: 48.3vh;
      left: 20.7vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2);
        }
      }
    }

    .a-music {
      position: absolute;
      width: 40px;
      // top: calc(100vh / 2 - 8.2%);
      // left: calc(100vw / 6 - 1%);
      top: 40.8vh;
      left: 11vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2) !important;
        }
      }
    }

    .main-asix {
      position: absolute;
      width: 45px;
      // top: calc(100vh / 2 - 7%);
      // left: calc(100vw / 2 - 5.7%);
      top: 43vh;
      left: 43vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2);
        }
      }
    }

    .nusantara-verse {
      position: absolute;
      width: 55px;
      // top: calc(100vh / 2);
      // right: calc(100vw / 7);
      top: 50vh;
      right: 8vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2) !important;
        }
      }
    }

    .earn {
      position: absolute;
      width: 40px;
      // top: calc(100vh / 2 - 6%);
      // right: calc(100vw / 9);
      top: 43.4vh;
      right: 6vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2) !important;
        }
      }
    }
  }
}

// MOBLIE Galaxi s 8
@media screen and (width: 412px),
screen and (height: 915px) {
  .app {
    .img-bg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    height: 100vh;
    background-size: 100vw 100vh;
    background-position: center center;
    position: relative;
    width: 100vw;

    .pasar-nft {
      position: absolute;
      width: 44px;
      // top: 47.3%;
      // left: 29%;
      top: 48.6vh;
      left: 25.5vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2);
        }
      }
    }

    .a-music {
      position: absolute;
      width: 40px;
      // top: calc(100vh / 2 - 8.2%);
      // left: calc(100vw / 6 - 1%);
      top: 42vh;
      left: 9vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2) !important;
        }
      }
    }

    .main-asix {
      position: absolute;
      width: 45px;
      // top: calc(100vh / 2 - 7%);
      // left: calc(100vw / 2 - 5.7%);
      top: 44vh;
      left: 44vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2);
        }
      }
    }

    .nusantara-verse {
      position: absolute;
      width: 55px;
      // top: calc(100vh / 2);
      // right: calc(100vw / 7);
      top: 50vh;
      right: 7vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2) !important;
        }
      }
    }

    .earn {
      position: absolute;
      width: 40px;
      // top: calc(100vh / 2 - 6%);
      // right: calc(100vw / 9);
      top: 43.4vh;
      right: 6vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2) !important;
        }
      }
    }
  }
}

// MOBLIE Galaxi 5
@media screen and (width: 360px),
screen and (height: 640px) {
  .app {
    .img-bg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    height: 100vh;
    background-size: 100vw 100vh;
    background-position: center center;
    position: relative;
    width: 100vw;

    .pasar-nft {
      position: absolute;
      width: 44px;
      top: 46.8vh;
      left: 27.5vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2);
        }
      }
    }

    .a-music {
      position: absolute;
      width: 40px;
      top: 39.7vh;
      left: 14vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2) !important;
        }
      }
    }

    .main-asix {
      position: absolute;
      width: 45px;
      top: 42vh;
      left: 42vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2);
        }
      }
    }

    .nusantara-verse {
      position: absolute;
      width: 55px;
      // top: calc(100vh / 2);
      // right: calc(100vw / 7);
      top: 50vh;
      right: 12vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2) !important;
        }
      }
    }

    .earn {
      position: absolute;
      width: 40px;
      // top: calc(100vh / 2 - 6%);
      // right: calc(100vw / 9);
      top: 42.4vh;
      right: 12vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2) !important;
        }
      }
    }
  }
}

// MOBLIE Pixel 2
@media screen and (width: 411px),
screen and (height: 730px) {
  .app {
    .img-bg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    height: 100vh;
    background-size: 100vw 100vh;
    background-position: center center;
    position: relative;
    width: 100vw;

    .a-music {
      position: absolute;
      width: 42px;
      top: 40.55vh;
      left: 14.6vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2) !important;
        }
      }
    }

    .pasar-nft {
      position: absolute;
      width: 44px;
      top: 47.5vh;
      left: 28.5vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2);
        }
      }
    }



    .main-asix {
      position: absolute;
      width: 45px;
      top: 43vh;
      left: 43vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2);
        }
      }
    }

    .nusantara-verse {
      position: absolute;
      width: 55px;
      // top: calc(100vh / 2);
      // right: calc(100vw / 7);
      top: 50vh;
      right: 12vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2) !important;
        }
      }
    }

    .earn {
      position: absolute;
      width: 40px;
      // top: calc(100vh / 2 - 6%);
      // right: calc(100vw / 9);
      top: 42.4vh;
      right: 12vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2) !important;
        }
      }
    }
  }
}

// MOBLIE POCO
@media screen and (width: 540px),
screen and (min-height: 1008px) {
  .app {
    .img-bg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    height: 100vh;
    background-size: 100vw 100vh;
    background-position: center center;
    position: relative;
    width: 100vw;

    .a-music {
      position: absolute;
      width: 45px;
      top: 41.9vh;
      left: 14.6vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2) !important;
        }
      }
    }

    .pasar-nft {
      position: absolute;
      width: 45px;
      top: 48.9vh;
      left: 29.5vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2);
        }
      }
    }



    .main-asix {
      position: absolute;
      width: 47px;
      top: 44vh;
      left: 44vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2);
        }
      }
    }

    .nusantara-verse {
      position: absolute;
      width: 55px;
      // top: calc(100vh / 2);
      // right: calc(100vw / 7);
      top: 50vh;
      right: 12vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2) !important;
        }
      }
    }

    .earn {
      position: absolute;
      width: 40px;
      // top: calc(100vh / 2 - 6%);
      // right: calc(100vw / 9);
      top: 42.4vh;
      right: 12vw;

      img {
        width: 100%;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.2) !important;
        }
      }
    }
  }
}















.main-asix-animate {
  animation: pound .4s infinite;
  animation-duration: 10s;
}

@keyframes pound {
  0% {
    transform: rotate(10deg);
  }

  25% {
    transform: rotate(5deg);
  }

  50% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(5deg);
  }

  100% {
    transform: rotate(10deg);
  }
}

.a-music-animate {
  animation: music 1s infinite;
  animation-duration: 10s;
}

@keyframes music {
  0% {
    transform: translateY(3px);
  }

  50% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(3px);
  }
}

.slide-top {
  -webkit-animation: slide-top 10s linear infinite alternate both;
  animation: slide-top 10s linear infinite alternate both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}


.slide-top-2 {
  -webkit-animation: slide-top-2 5s linear infinite alternate both;
  animation: slide-top-2 10s linear infinite alternate both;
}

@-webkit-keyframes slide-top-2 {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes slide-top-2 {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}