@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,800;1,700&display=swap');

* {
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Poppins', sans-serif;
}

.menu-trigger {
  position: fixed;
  top: 2%;
  left: 5%;
  z-index: 999;
  border-radius: 50%;
  display: flex;
  align-items: center;

  button {
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    background-color: #70BF6B;
    color: #FFF;
    border: none;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    transition: all ease-in-out .3s;

    &:hover {
      color: #70BF6B;
      background-color: #FFF;
      transform: scale(1.1);
    }
  }

  .link {
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    border: none;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    background-color: #70BF6B;
    color: #FFF;
    transition: all ease-in-out .3s;

    &:hover {
      color: #70BF6B;
      background-color: #FFF;
      transform: scale(1.1);
    }
  }
}

.sidemenu {
  position: fixed;
  width: 360px;
  background-color: rgba(43, 125, 53, 0.8);
  border-radius: 10px;
  top: 1%;
  bottom: 1%;
  left: 1%;
  transform: translate(-420px);
  transition: all ease .3s;
  z-index: 998;

  &.open {
    transform: translate(0);
  }

  @media (max-width:576px) {
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0;
  }

  .header {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo-wraper {
      .logo-img {
        width: 100px;

        &.nusantara {
          width: 200px;
        }
      }
    }
  }

  .body {
    .menus {
      display: flex;
      flex-direction: column;

      .menu-item {
        height: 40px;
        margin-bottom: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: #FFF;
        font-weight: 600;

        &.active {
          color: #ffd885;
          background-color: rgba(4, 93, 14, 0.9);
        }
      }
    }
  }
}

.actions {
  .btn-action-primary {
    height: 45px;
    width: 130px;
    background-color: #70BF6B;
    color: #FFF;
    font-weight: 600;
    border-radius: 50px;
    border: none;
    margin: 0 10px;
    transition: all ease-in-out .3s;
    cursor: pointer;

    &:hover {
      background-color: #FFF;
      color: #70BF6B;
      transform: scale(1.1);
    }
  }

  &.floating {
    position: absolute;
    right: 5%;
  }
}

.copyright {
  padding: 10px;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  left: 0;
  right: 0;

  &.fix {
    position: absolute;
    bottom: 10px;
  }
}

.heading-roadmap {
  text-align: center;
  margin-bottom: 60px;
  font-size: 56px;
  font-weight: 700;
  color: #ffffff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}

.item-roadmap {
  background-color: rgba(0, 0, 0, 0.7);
  color: #FFF;
  border-radius: 8px;
  padding: 10px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 10px #70BF6B;

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    span {
      background-color: #70BF6B;
      padding: 5px 10px;
      border-radius: 50px;
      font-size: 12px;
      font-weight: 800;
      color: #000;
    }
  }

  .circle {
    border: 4px solid #70BF6B;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
    font-size: 42px;
    color: #70BF6B;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 67px;
      height: 67px;
      border: 2px solid rgba(113, 191, 107, 0.6);
      position: absolute;
      z-index: 1;
      border-radius: 50%;
      bottom: 0;
    }

    &::before {
      content: "";
      display: block;
      width: 60px;
      height: 60px;
      border: 2px solid rgba(113, 191, 107, 0.4);
      position: absolute;
      z-index: 1;
      border-radius: 50%;
      bottom: 0;
    }
  }

  .list {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    .icon {
      margin-right: 11px;
      margin-bottom: 5px;
    }

    span {
      flex: 1;
      font-size: 12px;
      margin-top: 5px;
      font-weight: 400;
    }
  }
}


.social {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 0 20px 0;

  .link-social {
    text-decoration: none;
    color: #FFF;
    background-color: #70BF6B;
    margin: 3px;
    height: 40px;
    width: 40px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    transition: all ease-in-out .3s;

    &:hover {
      color: #70BF6B;
      background-color: #FFF;
      transform: scale(1.1);
    }

    @media (max-width:576px) {
      width: 35px;
      height: 35px;
      font-size: 22px;
    }
  }
}

.link-modal {
  position: fixed;
  right: 5%;
  top: 10%;
  width: 200px;
  background-color: #FFF;
  border-radius: 10px;
  z-index: 99;
  padding: 10px;

  &.hide {
    display: none;
  }
}

.btn-open-wp {
  position: fixed;
  top: 2%;
  right: 5%;
  background-color: #ffffff;
  color: #70BF6B;
  height: 45px;
  border-radius: 50px;
  padding: 0 20px;
  border: none;
  cursor: pointer;
  z-index: 999;
}

.link-whitepaper {
  // position: fixed;
  margin-bottom: 10px;
  outline: none;
  border: none;
  top: 2%;
  right: 5%;
  text-decoration: none;
  background-color: #70BF6B;
  color: #FFF;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 500;
  // box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  z-index: 999;
  transition: all ease-in-out .3s;

  &:hover {
    // background-color: #FFF;
    color: #FFF;
    // transform: scale(1.1);
  }
}




.slide-bottom {
  -webkit-animation: slide-bottom 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite alternate;
  animation: slide-bottom 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite alternate;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-6-30 1:33:30
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
}



.main-loader {
  background-image: url("./assets/bg/loader-bg.png");
  position: fixed;
  background-size: 100%;
  background-position: center;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  .loader-box {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 5px rgba(0, 0, 0, .1);
    background-image: url("./assets/main-asix.png");
    background-size: cover;
  }
}

.bg-partner {
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.7);

  .partner-ship-link {
    border: 1px solid #70BF6B;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all ease-in-out .3s;

    &:hover {
      transform: scale(1.1);
      background-color: rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    }
  }
}

.pnf {
  width: 100vh;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #232323;
  width: 100vw;
  height: 100vh;

  .img-container {

    width: 320px;
    height: 320px;

    @media (min-width:576px) {
      width: 520px;
      height: 520px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}