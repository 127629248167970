.dev {
  position: relative;

  .btn-back-home {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    background-color: #70BF6B;
    left: 2%;
    top: 2%;
    color: #fff;
    font-size: 32px;
    border-radius: 50%;
  }

  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    z-index: 9;
    background-image: linear-gradient(rgba(0, 0, 0, .5),
        rgba(0, 0, 0, 0.1),
        rgba(0, 0, 0, .5)), url("../../assets/bg-dev.png");
    background-size: cover;
    background-position: bottom;

    .logo {
      position: absolute;
      top: 50px;
      width: 100px;

      &.bigger {
        width: 230px;
      }

      img {
        width: 100%;
        transition: all ease-in-out .2s;

        &:hover {
          transform: scale(1.2);
        }
      }
    }

    .title {
      color: #fff;
      text-transform: uppercase;
      font-size: 86px;
      font-weight: 700;
      text-shadow: 0 1px 1px #000;
      text-align: center;
      text-shadow: 0 0 12px rgba(0, 0, 0, 0.5);

      @media (max-width:768px) {
        font-size: 56px;
      }
    }

    .timer {
      display: flex;

      .time {
        background-color: rgb(0, 0, 0);
        height: 70px;
        width: 70px;
        margin: 10px;
        font-size: 36px;
        font-weight: 800;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        // box-shadow: 0 0 10px 0px inset(0) #fff;
        box-shadow: inset 0px 2px 3px 5px rgba(255, 255, 255, 0.1),
          0px 0 3px 0px rgba(255, 255, 255, .8),
          0px 0 3px 0px rgba(255, 255, 255, .8),
          0px 0 3px 0px rgba(255, 255, 255, .8),
          inset 0 0px 5px 0px rgba(255, 255, 255, 0.6),
          0px 0 3px 0px rgba(255, 255, 255, .8);
        border-radius: 5px;
      }
    }
  }

  .dev-social {
    position: absolute;
    bottom: 0;
  }

  .footer {
    position: absolute;
    bottom: 20px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      width: 50px;
      height: 50px;
      font-size: 28px;
      background-color: rgb(255, 255, 255);
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px;
      border-radius: 50%;
      color: #000;
      transition: all ease-in-out 0.3s;

      &:hover {
        transform: scale(1.2);
        box-shadow: 0 0 10px rgb(255, 255, 255);
        background-color: #000;
        color: #fff;
      }
    }
  }
}

.scale-up-center {
  -webkit-animation: scale-up-center 3s linear infinite alternate both;
  animation: scale-up-center 3s linear infinite alternate both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}