.page {
  background: url("../../assets/main-bg-full.png"), url("../../assets/bg/bg-fallback-full.png");
  min-height: 100vh;
  background-size: cover;
  background-attachment: fixed;
  overflow: auto;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;

  .content-top-main {
    height: 100vh;
    display: flex;
    align-items: center;

    .infos-main {
      background-color: rgba(0, 30, 10, 0.8);
      border-radius: 10px;
      color: #fff;

      .title {
        font-weight: 400;
      }

      .fitur-name {
        color: #70bf6c;
        font-size: 46px;
        font-weight: 600;
      }

      .desc-main {
        font-weight: 400;
        font-size: 18px;
      }

      .smart-contract-copy {

        p {
          padding: 6px;
          margin-top: 5px;
          border-radius: 3px;
          font-size: 18px;
          font-weight: 500;

          &.small {
            font-size: 14px;
            text-align: center;
          }

          &.copied {
            background-color: #FFF;
            color: #70bf6c;

          }
        }
      }

      .btn-copy {
        color: #70bf6c;
        padding: 5px;
        border: none;
        border-radius: 5px;
        transition: transform ease-in-out .3s;

        &:hover {
          color: #FFF;
          background-color: #70bf6c;
          transform: scale(1.05);
        }
      }
    }
  }

  .content-top {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .btn-man-scroll {
      position: absolute;
      bottom: 100px;
      background-color: transparent;
      color: #fff;
      font-size: 32px;
      border: none;
    }

    .infos {
      position: absolute;
      background-color: rgba(0, 30, 10, 0.8);
      width: 90vw;
      right: 5px;
      padding: 20px;
      border-radius: 10px;
      color: #fff;

      .title {
        font-weight: 400;
      }

      .fitur-name {
        color: #70bf6c;
        font-size: 46px;
        font-weight: 600;
      }

      .desc {
        font-weight: 400;
        font-size: 14px;
      }

    }

    .copy-token {
      position: absolute;
      color: #FFF;
      font-weight: 400;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: wrap;
      bottom: 18%;
      right: 10px;



      .btn-copy {
        color: #70bf6c;
        padding: 5px;
        border: none;
        border-radius: 5px;
        transition: transform ease-in-out .3s;

        &:hover {
          color: #FFF;
          background-color: #70bf6c;
          transform: scale(1.05);
        }
      }

      div {
        text-shadow: 0.5px 0 1px rgba(0, 0, 0, 0.3);
        // background-color: #70bf6c;
        background-color: rgba(0, 30, 10, 0.8);
        padding: 3px;
        border-radius: 5px;

      }

      span {
        font-size: 16px;
        text-shadow: 0.5px 0 1px rgba(0, 0, 0, 0.8);
        font-weight: 500;

        &.copied {
          background-color: #fff;
          color: #70bf6c;
        }
      }

    }
  }


  .box-buy-on {
    position: absolute;
    top: 180px;
    height: 90vh;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    z-index: 99;

    .item-link {
      text-decoration: none;
      color: #20671c;
      background-color: #FFF;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border-radius: 0 50px 50px 0;
      padding: 5px;
      transition: all ease-in-out .3s;
      margin: 10px 0;
      width: 50px;

      .logo-buy {
        width: 40px;
        height: 40px;
        margin-left: 5px;
        border-radius: 50%;
      }

      .text-buy {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 14px;
        width: 0;
        display: block;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        &.soon {
          font-size: 14px;
          font-weight: 600;
        }

        small {
          font-size: 8px;
        }
      }

      &:hover {
        transform: scale(1.02);
        background-color: #70bf6c;
        color: #FFF;
        width: 100%;

        .text-buy {
          color: #FFF;
          width: 100%;

          &.soon {
            color: #0e520a;
          }
        }
      }

    }

    @media (max-width:640px) {
      margin-bottom: 20px;

      .item-link {
        width: 35px;
        padding: 3px;
        margin: 4px 0;

        .logo-buy {
          width: 30px;
          height: 30px;
          margin-left: 0;
        }

        .text-buy {
          text-transform: uppercase;
          font-weight: 400;
          font-size: 14px;
        }

      }
    }

  }
}

.main-asix-content {
  background-image: linear-gradient(transparent,
      rgba(0, 0, 0, 0.5),
      transparent);
  color: #70bf6c;
  // margin-top: 100px;
  padding: 30px 0;

  .left-box {
    transform: translateX(-20px);
    background-color: rgba(0, 0, 0, 0.7);
    position: relative;
    padding: 50px 50px 50px 70px;
    border: 2px solid #70bf6c;
    border-radius: 10px;

    .title {
      font-size: 48px;
      font-weight: 600;
    }

    span {
      color: #fff;
    }

    .actions {
      position: absolute;
      bottom: -20px;
    }

    @media (max-width: 768px) {
      .title {
        font-size: 38px;
        font-weight: 600;
      }

      span {
        color: #fff;
      }

      .actions {
        position: absolute;
        bottom: -20px;
      }
    }
  }
}

@media (min-width:576px) {
  .box-smart-contract {
    position: absolute;
    right: 20px;
    top: 77vh;
    width: 80%;

    .smart-contract-copy {
      border-radius: 8px;
    }
  }
}


@media (min-width: 768px) {

  .box-smart-contract {
    margin-top: 0;
    position: absolute;
    right: 10px;
    top: 67%;
    width: 60vw;

    .smart-contract-copy {
      border-radius: 8px;
    }
  }



  .page .content-top {
    position: relative;

    .infos {
      position: absolute;
      background-color: rgba(0, 30, 10, 0.8);
      width: 60vw;
      padding: 20px;
      border-radius: 10px;
      color: #fff;

      .title {
        font-weight: 400;
      }

      .fitur-name {
        color: #70bf6c;
        font-size: 68px;
        font-weight: 600;
      }

      .desc {
        font-weight: 400;
        font-size: 18px;
      }

    }

    .copy-token {
      position: absolute;
      color: #FFF;
      font-weight: 400;
      bottom: 25%;

      div {
        text-shadow: 0.5px 0 1px rgba(0, 0, 0, 0.3);
        background-color: rgba(0, 30, 10, 0.8);
        padding: 3px;
        border-radius: 5px;
      }

      span {
        font-size: 18px;

      }
    }
  }
}

@media (min-width: 992px) {

  .box-smart-contract {
    margin-top: 0;
    position: absolute;
    right: 10px;
    top: 67%;
    width: 50%;

    .smart-contract-copy {
      border-radius: 8px;
    }
  }

  .page {

    .box-buy-on {
      height: 70vh;

    }
  }


  .page .content-top {
    .infos {
      width: 50vw;
    }

    .copy-token {
      position: absolute;
      color: #FFF;
      font-weight: 400;
      bottom: 25%;

      div {
        text-shadow: 0.5px 0 1px rgba(0, 0, 0, 0.3);
        // background-color: #70bf6c;
        background-color: rgba(0, 30, 10, 0.8);
        padding: 3px;
        border-radius: 5px;
      }

      span {
        font-size: 18px;

      }
    }
  }


}